import styled from 'styled-components'

export const MenuContainer = styled.div`
    /* color: var(--darkest);
    background: var(--darkest);
    display: flex;
    justify-content: center;
    align-items: center; */
    /* padding: 0 30px; */
    /* width: 100%;
    height: 100vh;
    z-index: 3;
    flex-wrap: wrap;
    margin-bottom: 24px; */

    /* @media screen and (max-width: 768px) {
        padding: 100px 0;
    } */

    padding: 4rem;
    background: var(--darkest);
`

export const MenuGrid = styled.div`
    /* display: grid;
    z-index: 3;
    height: 860px;
    width: 100%;

    grid-template-columns: 1fr 2fr 2fr 1fr;
    grid-template-rows: 20px 150px 600px 20px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 2fr 2fr 1fr;
        grid-template-rows: 10px 140px 500px 10px;
    } */

    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    /* width: 90%; */
    margin: 0 auto;
`

export const MenuTitleWrapper = styled.div`
    /* margin-top: 60px; */
    display: grid;
    align-items: center;
    justify-content: center;
    /* postition: relative; */
    /* grid-column: 2/4;
    grid-row: 2; */

    /* @media screen and (max-width: 768px) {
        margin-top: 10px;
    } */
`

export const MenuTitle = styled.h1`
    color: var(--mid);
    font-size: 70px;
    font-family: 'Great Vibes', cursive;
    position: relative;

    &:before {
        content: "";
        display: block;
        width: 275px;
        height: 2px;
        position: absolute;
        background: var(--mid);
        right: 175px;
        top: 50%;
    }
    &:after {
        content: "";
        display: block;
        width: 275px;
        height: 2px;
        position: absolute;
        background: var(--mid);
        left: 175px;
        top: 50%;
    }

    @media screen and (max-width: 768px) {
        /* top: 20%; */
        font-size: 50px;

        &:before {
            width: 100px;
            right: 130px;
        }
        &:after {
            width: 100px;
            left: 130px;
        }
    }
`;

export const MenuInfoWrapper = styled.div`
    /* margin-top: 20px; */
    justify-items: stretch;
    align-items: stretch;
    display: grid;
    align-items: center;
    justify-content: center;
    /* grid-column: 2/4;
    grid-row: 3; */

    grid-template-rows: 1fr 1fr 1fr;

    @media screen and (max-width: 768px) {
        /* margin-top: 0px; */
    }
`
export const MenuSubtitleWrapper = styled.div`
    position: center;
    float: center;
    text-align: center;
    display: grid;
    justify-content: center;
    text-align: center;

    grid-row: ${props => props.gridRow || 1};

    grid-template-rows: 80px 110px;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 768px) {
        /* margin-top: 5px; */
        grid-template-rows: 110px 125px;
    }
`

export const MenuSubtitle = styled.h2`
    color: var(--mid);
    font-size: 40px;
    font-family: 'Great Vibes', cursive;
    justify-content: center;
    position: center;
    float: center;
    text-align: center;
    /* postition: relative; */

    grid-row: 1;
    grid-column: 1 / 4;

    @media screen and (max-width: 768px) {
        /* margin-top: 10px; */
    }
`

export const MenuItems = styled.ul`
    grid-row: 2;
    grid-column: ${props => props.gridColumn || 1};
    text-align: center;
    align-items: top;
    justify-content: center;
    display: grid;

    /* @media screen and (max-width: 768px) {
        margin-top: 20px;
    } */
`

export const MenuItemsLarge = styled.ul`
    grid-row: 2;
    grid-column: ${props => props.gridColumn || 1};
    text-align: center;
    align-items: top;
    justify-content: center;
    display: grid;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
        padding-bottom: 20px;
    }
`

export const MenuItem = styled.li`
    color: var(--lightest);
    text-align: left;
    font-size: 20px;

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
`