import React, { useEffect, useState } from 'react'
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa'
import { RightArrow, LeftArrow, Slider, SliderImage } from './ImageSliderElements'
import './SliderStyles.css'

const ImageSlider = () => {

    const importAll = (r) => {
        return r.keys().map(r);
      };
    
    // const [backendData, setBackendData] = useState([{}])
    const [backendData, setBackendData] = useState(importAll(
        require.context("../../photos", false, /\.jpg$/)
    ))
    const [current, setCurrent] = useState(0)
    const length = backendData.length

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }

    
    // Use this when the security issues with backend are resolved
    // useEffect(() => {
    //     fetch("https://backend.heavenlydelightcakes.com/refresh-photos")
    //     fetch("https://backend.heavenlydelightcakes.com/photo-urls").then(
    //     response => response.json()
    // ).then(
    //     data => {
    //     setBackendData(data)
    //     }
         
    // )
    // }, [])

    if (!Array.isArray(backendData) || backendData.length <= 0) {
        return null;
    }

  return (
    <Slider>
        <LeftArrow>
            <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide}/>
        </LeftArrow>
        <RightArrow>
            <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide}/>
        </RightArrow>
        
        {backendData.map((slide, index) => {
            return (
                <div className={index === current ? 'slide active' : 'slide'} key={index}>
                    {index === current && (<SliderImage src={slide} alt=""/>)}
                </div>
            )
        })}
        
    </Slider>
  )
}

export default ImageSlider