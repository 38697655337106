import React, { useState } from 'react'
import Navbar from '../Navbar'
import HeroSection from '../HeroSection'
import Sidebar from '../Sidebar'
import MenuSection from '../MenuSection'
import GallerySection from '../GallerySection'
import InfoSection from '../ContactSection'
import { homeObjOne } from '../ContactSection/Data'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <HeroSection id="home"/>
        <MenuSection id="menu"/>
        <GallerySection id="gallery"/>
        <InfoSection {...homeObjOne} id="contact"/>
    </>
  )
}

export default Home