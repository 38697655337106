import styled from "styled-components";

export const InfoContainer = styled.div`
    /* color: var(--lightest);
    background: ${({lightBg}) => (lightBg ? 'var(--lightest)' : 'var(--darkest)')};

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    } */

    padding: 4rem;
    background: var(--darkest);
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    /* height: 860px; */
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'` )};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    /* max-width: 540px; */
    padding-top: 0;
    /* padding-bottom: 60px; */
`

export const TopLine = styled.p`
    color: var(--mid);
    font-size: 50px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 20px;
    font-family: 'Great Vibes', cursive;
`

export const Heading = styled.h1`
    margin-bottom: 1px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: bold;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.h2`
    max-width: 440px;
    margin-bottom: 50px;
    margin-top: 10px;
    font-size: 30px;
    line-height: 24px;
    font-weight: bold;
    color: #f7f8fa;
`
export const Subtitle2 = styled.h3`
    max-width: 440px;
    margin-bottom: 5px;
    margin-top: 15px;
    font-size: 25px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? "var(--darkest)" : "var(--lightest)")};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
`

export const Img = styled.img`
    width: 100%;
    /* margin: 0 0 10px 0; */
    padding-right: 0; 

    @media screen and (max-width: 768px) {
        margin: 0 0 0 0;
    }
`