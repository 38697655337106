import styled from "styled-components";


export const Slider = styled.section`
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SliderImage = styled.img`
    max-height:500px;
    max-width:500px;
    height:auto;
    width:auto;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
        max-height: 400px;
        max-width: 400px;
    }
`

export const RightArrow = styled.div`
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: var(--darkest);
    z-index: 10;
    cursor: pointer;
    user-select: none;

    &:hover {
        color: var(--mid);
        transition: 0.2s ease-in-out;
    }

    @media screen and (max-width: 768px) {
        top: 80%;
        right: 0px;
    }
`

export const LeftArrow = styled.div`
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: var(--darkest);
    z-index: 10;
    cursor: pointer;
    user-select: none;

    &:hover {
        color: var(--mid);
        transition: 0.2s ease-in-out;
    }

    @media screen and (max-width: 768px) {
        top: 80%;
        left: 0px;
    }
`
