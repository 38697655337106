import styled from 'styled-components';
import {GiCakeSlice} from 'react-icons/gi';

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    flex-wrap: wrap;
    object-fit: contain;
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden; 
    background-image: linear-gradient(45deg, var(--darker), var(--mid), var(--lighter));
    background-size: 200%;
    animation: bg-animation 10s infinite alternate;

    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed; //This causes it to cover the whole screen for some reason
    z-index: -1;

    @keyframes bg-animation {
        0% {
            background-position: left;
        }
        100% {
            background-position: right;
        }
    }
`;

export const HeroContent = styled.div`
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const CakeIcon = styled(GiCakeSlice)`
    color: var(--lightest);
    font-size: 70px;
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    margin-top: -100px;

    @media screen and (max-width: 768px) {
        font-size: 50px;
    }
`

export const HeroH1 = styled.h1`
    color: var(--lightest);
    font-size: 70px;
    text-align: center;
    font-family: 'Great Vibes', cursive;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        font-size: 45px;
    }
`;

export const HeroH2Line = styled.h2`
    color: var(--darkest);
    font-size: 50px;
    text-align: center;
    font-family: 'Great Vibes', cursive;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;

    &:before {
        content: "";
        display: block;
        width: 275px;
        height: 2px;
        position: absolute;
        background: var(--darkest);
        right: 60px;
        top: 50%;
        z-index: -2;
    }
    &:after {
        content: "";
        display: block;
        width: 275px;
        height: 2px;
        position: absolute;
        background: var(--darkest);
        left: 60px;
        top: 50%;
        z-index: -2;
    }

    @media screen and (max-width: 768px) {
        font-size: 35px;

        &:before {
            width: 100px;
            right: 45px;
        }
        &:after {
        width: 100px;
        left: 45px;
        }
    }
`;


export const HeroH2 = styled.h2`
    color: var(--darkest);
    font-size: 50px;
    text-align: center;
    font-family: 'Great Vibes', cursive;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        font-size: 35px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: var(--darker);
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;