import styled from "styled-components";

export const GalleryContainer = styled.div`
    padding: 4rem;
    background: var(--lightest);
`

export const GalleryGrid = styled.div`
    display: grid;
    z-index: 1;
    /* height: 860px; */
    width: 100%;

    grid-template-columns: 1fr 2fr 2fr 1fr;
    grid-template-rows: 20px 150px 600px 20px;
`

export const GalleryTitleWrapper = styled.div`
    /* margin-top: 60px; */
    display: grid;
    align-items: center;
    justify-content: center;
    /* grid-column: 2/4;
    grid-row: 2; */
`

export const GalleryTitle = styled.h1`
    color: var(--darkest);
    font-size: 70px;
    font-family: 'Great Vibes', cursive;
    position: relative;

    &:before {
        content: "";
        display: block;
        width: 280px;
        height: 2px;
        position: absolute;
        background: var(--darkest);
        right: 185px;
        top: 50%;
    }
    &:after {
        content: "";
        display: block;
        width: 280px;
        height: 2px;
        position: absolute;
        background: var(--darkest);
        left: 185px;
        top: 50%;
    }

    @media screen and (max-width: 768px) {
        /* top: 20%; */
        font-size: 50px;

        &:before {
            width: 100px;
            right: 130px;
        }
        &:after {
            width: 100px;
            left: 130px;
        }
    }
`;

export const GalleryContentWrapper = styled.div`
    /* display: grid; */
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -150px;
`

export const GalleryTempTitle = styled.h1`
    color: var(--darkest);
    font-size: 70px;
    position: relative;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 35px;
    }
`;