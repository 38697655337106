import React from 'react';
import {
    Nav, 
    NavbarContainer, 
    NavLogo,
    NavMenu,
    NavLinks, 
    MobileIcon
} from './NavbarElements';
import {FaBars} from 'react-icons/fa';
import {GiCakeSlice} from 'react-icons/gi';

const Navbar
 = ({toggle}) => {
  return (
    <>
        <Nav>
            <NavbarContainer>
                <NavLogo to='home' smooth='true'>
                    <GiCakeSlice font-size="35px"/>
                </NavLogo>

                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>

                <NavMenu>
                    <NavLinks to='menu' smooth='true'>Menu</NavLinks>
                    <NavLinks to='gallery' smooth='true'>Gallery</NavLinks>
                    <NavLinks to='contact' smooth='true'>Contact</NavLinks>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    </>
  );
};

export default Navbar;