import {BrowserRouter as Router} from 'react-router-dom'
import Home from "./components/Pages";
import './App.css';
import React, { useEffect, useState } from 'react';

function App() {

  return (
    <Router>
      <Home/>
    </Router>
  );
}

export default App;
