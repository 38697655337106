import React from 'react'
import { MenuContainer, MenuGrid, MenuTitle, MenuTitleWrapper, MenuInfoWrapper, MenuSubtitle, MenuSubtitleWrapper, MenuItems, MenuItem, MenuItemsLarge } from './MenuElements'

const MenuSection = ({id}) => {
  return (
    <MenuContainer id={id}>
        <MenuTitleWrapper>
            <MenuTitle>Menu</MenuTitle>
        </MenuTitleWrapper>
        <MenuGrid>
            <MenuInfoWrapper>
                <MenuSubtitleWrapper gridRow="1">
                <MenuSubtitle>Signature Cake Flavors</MenuSubtitle>
                <MenuItemsLarge gridColumn="1">
                    <MenuItem>Strawberry Swirl</MenuItem>
                    <MenuItem>Heavenly White</MenuItem>
                    <MenuItem>Chocolate</MenuItem>
                </MenuItemsLarge>
                <MenuItemsLarge gridColumn="2">
                    <MenuItem>Lemon</MenuItem>
                    <MenuItem>Carrot</MenuItem>
                    <MenuItem>Red Velvet</MenuItem>
                </MenuItemsLarge>
                <MenuItemsLarge gridColumn="3">
                    <MenuItem>Butter Pecan</MenuItem>
                    <MenuItem>Yellow</MenuItem>
                    <MenuItem>Marble</MenuItem>
                </MenuItemsLarge>
                </MenuSubtitleWrapper>
                
                <MenuSubtitleWrapper gridRow="2">
                <MenuSubtitle>Wedding Cakes</MenuSubtitle>
                    <MenuItems gridColumn="1 / 4">
                        <MenuItem>Wedding cakes priced by design</MenuItem>
                        <MenuItem>Price starts at $3.00 per serving</MenuItem>
                    </MenuItems>
                </MenuSubtitleWrapper>

                <MenuSubtitleWrapper gridRow="3">
                <MenuSubtitle>Cupcakes</MenuSubtitle>
                    <MenuItems gridColumn="1 / 4">
                        <MenuItem>25 signature flavors, available upon request</MenuItem>
                    </MenuItems>
                </MenuSubtitleWrapper>
            </MenuInfoWrapper>
        </MenuGrid>
    </MenuContainer>
  )
}

export default MenuSection