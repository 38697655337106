import React, { useState } from 'react'
import { HeroContainer, HeroBg, HeroContent, HeroH1, HeroH2, HeroBtnWrapper, OuterCircle, HeroH2Line, CakeIcon } from './HeroElements'

const HeroSection
 = ({id}) => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };

  return (
    <HeroContainer id={id}>
        <HeroBg/>
        <HeroContent>
            <CakeIcon/>
            <HeroH1>Heavenly Delight</HeroH1>
            <HeroH1>Cakes</HeroH1>
            <HeroH2Line>by</HeroH2Line>
            <HeroH2>Connie Adams</HeroH2>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection