export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Contact',
    headline: 'Connie Adams',
    description: 'Phone Orders Only',
    buttonLabel: 'Get started',
    imgStart: true,
    img: require('../../images/hc_cake.png'),
    alt: 'Cake',
    dark: true,
    primary: true,
    darkText: false
};