import React from 'react'
import { GalleryContainer, GalleryGrid, GalleryTitle, GalleryTitleWrapper, GalleryContentWrapper, GalleryTempTitle } from './GalleryElements'
import ImageSlider from './ImageSlider';

const GallerySection = ({id}) => {
  return (
    <GalleryContainer id={id}>
      <GalleryTitleWrapper>
        <GalleryTitle>Gallery</GalleryTitle>
      </GalleryTitleWrapper>
        <GalleryContentWrapper>
          <ImageSlider/>
        </GalleryContentWrapper>
    </GalleryContainer>
  )
}

export default GallerySection