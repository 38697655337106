import React from 'react';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, ImgWrap, TextWrapper, TopLine, Heading, Subtitle, Img, Subtitle2 } from './InfoElements';

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, img, alt }) => {
  return (
    <>
<InfoContainer lightBg={lightBg} id={id}>
    <InfoWrapper>
        <InfoRow imgStart={imgStart}>
            <Column1>
            <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle2 darkText={darkText}>(217) 235 - 4361</Subtitle2>
                <Subtitle2 darkText={darkText}>908 Charleston Ave.</Subtitle2>
                <Subtitle2 darkText={darkText}>Mattoon, IL 61938</Subtitle2>
            </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                    <Img src={img} alt={alt}/>
                </ImgWrap>
            </Column2>
        </InfoRow>
    </InfoWrapper>
</InfoContainer>
    </>
  )
}

export default InfoSection